import React, { useMemo } from 'react'
import { SectionComponentType } from '../../constants/sections'
import SectionLayout from '../../layouts/section-layout/SectionLayout'
import renderSection, { ISectionData } from '../../utils/renderSection'
import { IDefaultSectionData } from '../page-sections/default-section'
import { IGridSectionData } from '../page-sections/grid-section'
import { ITabsSectionData } from '../page-sections/tabs-section'
import { LazyProvider } from '../../contexts/lazy-provider/LazyProvider'
import { ISimpleCardsSectionData } from '../page-sections/cards-section/simple-cards-section/SimpleCardsSection'
import { SectionsProvider } from '../../contexts/sections-provider/SectionsProvider'
import { IBlurredSectionData } from '../page-sections/blurred-section/BlurredSection'

export interface ISectionComponentProps {
  sections: Array<ISectionData>
}

const PageMainContent: React.FC<ISectionComponentProps> = ({ sections }) => {
  const layoutSections = useMemo(() => {
    const internalSectionIds = new Set()
    sections.forEach(section => {
      if (section.strapi_component === SectionComponentType.TABS_LAYOUT) {
        const sectionData = section as ITabsSectionData
        sectionData.tabs.forEach(tab => internalSectionIds.add(tab.sectionId))
      }
      if (section.strapi_component === SectionComponentType.GRID_LAYOUT) {
        const sectionData = section as IGridSectionData
        sectionData.blocks.forEach(block => internalSectionIds.add(block.sectionId))
      }
      if (section.strapi_component === SectionComponentType.DEFAULT_SECTION) {
        const sectionData = section as IDefaultSectionData
        if (sectionData.contentId) {
          internalSectionIds.add(sectionData.contentId)
        }
      }
      if (section.strapi_component === SectionComponentType.SIMPLE_CARDS_SECTION) {
        const sectionData = section as ISimpleCardsSectionData
        if (sectionData.customCardId) {
          internalSectionIds.add(sectionData.customCardId)
        }
      }
      if (section.strapi_component === SectionComponentType.BLURRED_SECTION) {
        const sectionData = section as IBlurredSectionData
        if (sectionData.sectionId) {
          internalSectionIds.add(sectionData.sectionId)
        }
      }
    })
    return sections.filter(section => !internalSectionIds.has(section.options.anchor))
  }, [ sections ])

  return (
    <SectionsProvider sections={sections} layoutSections={layoutSections}>
      {layoutSections.map((sectionData, index) => (
        <LazyProvider isLazy={index > 1} key={index}>
          <SectionLayout options={sectionData.options} section={sectionData}>
            {renderSection(sectionData)}
          </SectionLayout>
        </LazyProvider>
      ))}
    </SectionsProvider>
  )
}

export default PageMainContent
